<template>
  <div class="mt-2">
    <b-link class="p-2 p-sm-3">
      <b-img
        :src="blankPageLogo"
        alt="logo"
      />
    </b-link>

    <div class="p-2 p-sm-3">
      <div class="w-100">
        <h2 class="mb-1">
          About Us
        </h2>
        <p class="mb-2">
          Nikah Saja merupakan layanan perjodohan online terbaik di Indonesia.
          Mesin kami akan membantu Anda menemukan orang yang kompatibel melalui
          algoritma perjodohan kami, yang tentunya sudah disesuaikan berdasarkan
          preferensi / kriteria yang Anda cari dalam sebuah pasangan. Di Nikah
          Saja mengajak anda dan orang-orang single lainnya untuk menciptakan
          hubungan yang bermakna dan membuat hubungan yang memiliki tujuan. <br> <br>
          Dimulai dari melakukan langkah pertama dengan memberikan profil Anda
          (Fitur: Try To Match) kepada orang-orang menarik di sekitar Anda,
          Melihat foto, Menyukai foto, dan melihat minat seseorang untuk
          mengenal si Dia dengan lebih baik. Jelajahi profil untuk mempelajari
          lebih detail tentang seseorang, seperti kepercayaan dan latar belakang
          pendidikan mereka. Sukai Profil seseorang dengan mengklik tombol "Try
          To Match", dan jika Si Dia menyukai Anda kembali, maka anda akan bisa
          memulai komunikasi via aplikasi Nikah Saja. <br> <br> Nikah Saja dibuat dengan
          tujuan untuk menjadi lebih dari sekadar aplikasi kencan, Kami ingin
          menciptakan komunitas kencan yang benar-benar inklusif dan terpercaya.
          Kami memiliki misi untuk mempertemukan dan mempermudah para orang
          lajang dalam menemukan belahan jiwa, khususnya bagi orang lajang yang
          sudah siap untuk berkomitmen membuat keluarga, tapi memiliki
          keterbatasan dalam waktu untuk mencari dan memulai perkenalan. Goals
          Kami adalah untuk menemukan pasangan ideal yang akan membuat Anda
          bahagia. Temukan Orang yang menarik di dekat Anda dan jalin hubungan
          dengan orang-orang yang memiliki minat, hobi, dan bahkan keyakinan
          yang cocok dengan Anda. <br> <br> Nikah Saja membantu perjalanan Anda untuk
          menemukan belahan jiwa Anda dengan cara yang sederhana, aman dan
          terjamin. Ini adalah komitmen Kami kepada para penggna Nikah Saja.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import { computed } from '@vue/composition-api'
import { useTitle } from '@vueuse/core'
import store from '@/store/index'

export default {
  components: {
    BLink,
    BImg,
  },
  setup() {
    const { appName, logo, logoSm } = $themeConfig.app

    useTitle(`About Us - ${appName}`)

    const blankPageLogo = computed(() => {
      if (store.getters['app/currentBreakPoint'] === 'xs') {
        return logoSm
      }

      return logo
    })

    return {
      appName,
      blankPageLogo,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';

@media (min-width: 768px) {
}
</style>
